<template>
    <div class="text-white ">
        <div class="page-header pt-5 pb-5">
            <h2 class="text-center font-weight-700">PRIVACY POLICY</h2>
            <h4 class="text-center font-weight-600 pt-3">CrowdQ Privacy Policy </h4>
        </div>
        <div class="container mt-5">
            <h4 class="font-weight-600">Welcome to CrowdQ!</h4>
            <p class="text-neutral-100 mt-2">At CrowdQ, we prioritize your privacy and are committed to protecting your
                personal information. This Privacy Policy explains how we collect, use, share, and safeguard your
                information when you access or use our platform, mobile application, and associated services
                (collectively, the "Service"). </p>
            <p class="text-neutral-100 mt-2">By using the CrowdQ Service, you agree to the practices described in this
                Privacy Policy. **If you do not agree, please discontinue use of the Service.** </p>
            <h4 class="font-weight-600 mt-3">1. Information We Collect</h4>
            <ul class="text-neutral-100 mt-2">
                <li><strong>a. Information You Provide to Us</strong></li>
                <ul class="ml-4">
                    <li><strong>Account Information:</strong> Name, email address, username, password, and optional
                        profile details (e.g., social media links).</li>
                    <li><strong>Song Requests:</strong> Information related to song requests, including song titles,
                        artists, and messages.</li>
                    <li><strong>Giveaway Participation:</strong> Data required for giveaways, such as name, contact
                        details, and eligibility verification information.</li>
                    <li><strong>Payment Information:</strong> For premium features or giveaways, processed securely
                        through third-party providers (e.g., Stripe).</li>
                    <li><strong>Communications:</strong> Details provided in your inquiries or customer support
                        requests.</li>
                </ul>

                <li><strong>b. Information We Collect Automatically</strong></li>
                <ul class="ml-4">
                    <li><strong>Device Information:</strong> IP address, browser type, operating system, and app
                        version.</li>
                    <li><strong>Usage Data:</strong> Interactions with the Service, such as features used and QR code
                        scans.</li>
                    <li><strong>Location Data:</strong> Approximate location via IP address or device settings (if
                        consented).</li>
                </ul>

                <li><strong>c. Information From Third Parties</strong></li>
                <ul class="ml-4">
                    <li><strong>Social Media:</strong> Data from linked accounts (e.g., Facebook, Instagram), as allowed
                        by your settings.</li>
                    <li><strong>Event Organizers:</strong> Information shared with us by event hosts when you
                        participate in their events or giveaways.</li>
                </ul>
            </ul>
            <h4 class="font-weight-600 mt-3">2. How We Use Your Information</h4>
            <ul class="text-neutral-100 mt-2">
                <li><strong>Operate the Service:</strong> Facilitate song requests, giveaways, and account management.
                </li>
                <li><strong>Personalize Experiences:</strong> Tailor content, events, or features to your preferences.
                </li>
                <li><strong>Communicate with You:</strong> Send transactional updates, event notifications, or
                    promotional materials (with your consent).</li>
                <li><strong>Ensure Compliance:</strong> Verify eligibility and adhere to legal requirements for
                    giveaways and other features.</li>
                <li><strong>Analyze and Improve:</strong> Conduct research, analyze trends, and enhance our platform.
                </li>
            </ul>

            <h4 class="font-weight-600 mt-3">3. How We Share Your Information</h4>
            <ul class="text-neutral-100 mt-2">
                <li><strong>With Service Providers:</strong> Payment processors, analytics providers, and other third
                    parties supporting our operations.</li>
                <li><strong>With Event Organizers:</strong> For song requests or giveaways, as necessary to fulfill
                    their features.</li>
                <li><strong>For Legal Reasons:</strong> To comply with applicable laws, regulations, or legal processes.
                </li>
                <li><strong>With Your Consent:</strong> When you explicitly agree to share information.</li>
            </ul>
            <h4 class="font-weight-600 mt-3">4. Cookies and Tracking</h4>
            <ul class="text-neutral-100 mt-2">
                <li><strong>Enhance User Experiences:</strong> Improve platform functionality.</li>
                <li><strong>Analyze Site Traffic:</strong> Monitor performance.</li>
            </ul>
            <p class="text-neutral-100 mt-2">You can manage cookies through your browser settings, but some features may
                not work properly if disabled.</p>

            <h4 class="font-weight-600 mt-3">5. Your Rights</h4>
            <ul class="text-neutral-100 mt-2">
                <li><strong>Access and Correct Data:</strong> View and update your personal information.</li>
                <li><strong>Delete Data:</strong> Request deletion of your data where applicable.</li>
                <li><strong>Opt-Out:</strong> Decline marketing communications or targeted ads.</li>
            </ul>
            <p class="text-neutral-100 mt-2">To exercise your rights, contact us at <a
                    href="mailto:admin@crowdq.live">admin@crowdq.live</a>.</p>
            <h4 class="font-weight-600 mt-3">6. Data Security</h4>
            <p class="text-neutral-100 mt-2">We use encryption, access controls, and regular audits to safeguard your
                data. While no system is completely secure, we strive to protect your information to the best of our
                ability.</p>

            <h4 class="font-weight-600 mt-3">7. Data Retention</h4>
            <p class="text-neutral-100 mt-2">We retain your information only as long as necessary to provide the Service
                or comply with legal obligations. Giveaway data is deleted after the giveaway is resolved unless
                required for legal reasons.</p>

            <h4 class="font-weight-600 mt-3">8. Children’s Privacy</h4>
            <p class="text-neutral-100 mt-2">CrowdQ is not intended for users under 13. If we discover we’ve collected
                data from a child under 13, we will delete it promptly.</p>

            <h4 class="font-weight-600 mt-3">9. Third-Party Links</h4>
            <p class="text-neutral-100 mt-2">Our platform may link to third-party sites. CrowdQ is not responsible for
                their privacy practices or content.</p>

            <h4 class="font-weight-600 mt-3">10. International Users</h4>
            <p class="text-neutral-100 mt-2">Your data may be stored and processed in the United States or other
                countries. By using the Service, you consent to such transfers.</p>

            <h4 class="font-weight-600 mt-3">11. Changes to This Privacy Policy</h4>
            <p class="text-neutral-100 mt-2">We may update this Privacy Policy periodically. Changes will be posted here
                with an updated effective date. Significant changes may be communicated directly.</p>

            <h4 class="font-weight-600 mt-3">12. Contact Us</h4>
            <p class="text-neutral-100 mt-2">If you have questions or concerns, contact us at:</p>
            <ul class="text-neutral-100 mt-2">
                <li><strong>Email:</strong> <a href="mailto:admin@crowdq.live">admin@crowdq.live</a></li>
            </ul>

        </div>
    </div>
</template>

<script>

</script>
<style scoped>
.page-header {
    background-image: url('../../../assets/util/images/banner.jpg');
    background-size: cover;
    background-position: center;
    height: 100%;

}
</style>
