<template>
    <div class="text-white ">
        <div class="page-header pt-5 pb-5">
            <h2 class="text-center font-weight-700">TERMS OF SERVICE </h2>
            <h4 class="text-center font-weight-600 pt-3">CrowdQ Terms of Service</h4>
        </div>
        <div class="container mt-5 mb-5">
            <h4 class="font-weight-600">Welcome to CrowdQ!</h4>
            <p class="text-neutral-100">CrowdQ is a dynamic platform designed to enhance real-time event engagement
                through innovative features like song requests and legally compliant giveaways. We are committed to
                continuously developing valuable tools that connect event organizers, DJs, and audiences in exciting new
                ways.</p>
            <p class="text-neutral-100 mt-2">These Terms of Service ("TOS") govern your access to and use of the CrowdQ
                platform, services, website, applications, and all associated features (collectively, the "Service"). By
                accessing or using our Service, you agree to be bound by these TOS and our Privacy Policy. **If you do
                not agree to these terms, please do not use the Service.**</p>
            <h4 class="font-weight-600 mt-3">1. Acceptance of Terms</h4>
            <p class="text-neutral-100 mt-2">By creating a CrowdQ account, accessing the Service, or using any of its
                features, you acknowledge that you have read, understood, and agree to be bound by these TOS. You also
                agree to comply with all applicable laws and regulations. If you are using the Service on behalf of an
                organization, you represent that you have the authority to bind that organization to these TOS.</p>
            <h4 class="font-weight-600 mt-3">2. Eligibility</h4>
            <ul class="text-neutral-100 mt-2">
                <li>You must be at least 18 years old to create an account or use features that involve financial
                    transactions (e.g., hosting giveaways with prizes of value).</li>
                <li>If you are under 18, you may only use the Service under the direct supervision of a parent or legal
                    guardian who agrees to be bound by these TOS.</li>
                <li>By using the Service, you represent and warrant that you meet these eligibility requirements and
                    have the legal capacity to enter into a binding agreement.</li>
            </ul>
            <h4 class="font-weight-600 mt-3">3. Description of Service</h4>
            <p class="text-neutral-100 mt-2">CrowdQ provides a suite of interactive tools designed to enhance events and
                audience engagement. Our current core features include:</p>
            <ul class="text-neutral-100 mt-2">
                <li>Song Request System: Allows event attendees to request songs in real time using QR codes, creating a
                    dynamic and participatory music experience.</li>
                <li>Giveaway Management: Enables event organizers and users to create and manage legally compliant
                    giveaways, contests, and sweepstakes, fostering audience interaction and excitement. .</li>
            </ul>
            <p>CrowdQ is dedicated to innovation and will continually evolve by adding new features and functionalities.
                These TOS will be updated to reflect any significant changes to the Service. </p>

            <h4 class="font-weight-600 mt-3">4. Use of the Service</h4>
            <p class="text-neutral-100 mt-2">You agree to use the CrowdQ Service only for lawful purposes and in a
                manner consistent with these TOS. You are prohibited from:</p>
            <ul class="text-neutral-100 mt-2">
                <li><span class="font-weight-bold">Interference:</span> Disrupting or interfering with the operation of
                    the Service, including its servers, networks, or security systems.</li>
                <li><span class="font-weight-bold">Fraudulent Activity:</span> Engaging in any deceptive, misleading, or
                    fraudulent activities on the platform.</li>
                <li><span class="font-weight-bold">Harmful Content:</span> Uploading, posting, or transmitting any
                    content that is unlawful, harmful, threatening, abusive, harassing, defamatory, vulgar, obscene,
                    invasive of another's privacy, hateful, or racially, ethnically, or otherwise objectionable.</li>
                <li><span class="font-weight-bold">Illegal Promotion:</span> Using the Service to promote or facilitate
                    any illegal activities.</li>
                <li><span class="font-weight-bold">Intellectual Property Infringement:</span> Infringing upon the
                    intellectual property rights of CrowdQ or any third party.</li>
                <li><span class="font-weight-bold">Reverse Engineering:</span> Attempting to reverse-engineer,
                    decompile, disassemble, or otherwise extract the source code of the Service or any part thereof.
                </li>
                <li><span class="font-weight-bold">Data Mining/Scraping:</span> Utilizing automated systems or software
                    to extract data from the service without express written consent.</li>
                <li><span class="font-weight-bold">Spamming:</span> Using the service to send unsolicited messages or
                    engage in any form of spamming.</li>
                <li><span class="font-weight-bold">Impersonation:</span> Impersonating any person or entity or falsely
                    stating your affiliation with a person or entity.</li>
                <li><span class="font-weight-bold">Unauthorized Access:</span> Attempting to gain unauthorized access to
                    the Service, user accounts, or computer systems or networks connected to the Service.</li>
                <li><span class="font-weight-bold">Violation of Laws:</span> Using the Service in violation of any
                    applicable local, state, national, or international laws or regulations.</li>
            </ul>

            <h4 class="font-weight-600 mt-3">5. Accounts and Security</h4>
            <ul class="text-neutral-100 mt-2">
                <li><span class="font-weight-bold">Account Creation:</span> To access certain features of the Service,
                    you may be required to create an account. You agree to provide accurate, current, and complete
                    information during the registration process and to update such information to keep it accurate,
                    current, and complete.</li>
                <li><span class="font-weight-bold">Account Security:</span> You are responsible for safeguarding your
                    account credentials (username and password) and for all activities that occur under your account. Do
                    not share your password with anyone.</li>
                <li><span class="font-weight-bold">Notification of Breach:</span> You agree to immediately notify CrowdQ
                    of any unauthorized use of your account or any other breach of security.</li>
                <li><span class="font-weight-bold">Account Termination:</span> CrowdQ reserves the right to suspend or
                    terminate your account at any time, with or without notice, if we believe you have violated these
                    TOS or engaged in any activity that is harmful to the Service or other users.</li>
            </ul>

            <h4 class="font-weight-600 mt-3">6. Content and Intellectual Property</h4>

            <h5 class="font-weight-600 mt-3">a. User-Generated Content:</h5>
            <ul class="text-neutral-100 mt-2">
                <li><span class="font-weight-bold">License Grant:</span> By submitting content to CrowdQ (including but
                    not limited to song requests, giveaway entries, comments, and profile information), you grant CrowdQ
                    a non-exclusive, worldwide, royalty-free, perpetual, irrevocable, sublicensable, and transferable
                    license to use, reproduce, modify, adapt, publish, translate, distribute, publicly perform, and
                    display such content in connection with the operation and promotion of the Service.</li>
                <li><span class="font-weight-bold">Responsibility for Content:</span> You are solely responsible for the
                    content you submit to the Service. You represent and warrant that you own or have obtained all
                    necessary rights, licenses, consents, and permissions to submit the content and to grant CrowdQ the
                    license described above.</li>
                <li><span class="font-weight-bold">Content Standards:</span> You agree not to submit any content that
                    violates these TOS, infringes upon the rights of others, or is otherwise unlawful or objectionable.
                </li>
                <li><span class="font-weight-bold">Content Removal:</span> CrowdQ reserves the right to remove any
                    user-generated content at any time, with or without notice, for any reason, including if we believe
                    the content violates these TOS or is harmful to the Service or other users.</li>
            </ul>

            <h5 class="font-weight-600 mt-3">b. CrowdQ Intellectual Property:</h5>
            <ul class="text-neutral-100 mt-2">
                <li><span class="font-weight-bold">Ownership:</span> CrowdQ retains all right, title, and interest in
                    and to the Service, including all software, technology, designs, trademarks, logos, copyrights, and
                    other intellectual property rights associated with the Service.</li>
                <li><span class="font-weight-bold">Restrictions:</span> You may not copy, modify, distribute, sell,
                    lease, sublicense, or create derivative works based on the Service or any part thereof without the
                    express written consent of CrowdQ.</li>
                <li><span class="font-weight-bold">Limited License:</span> Subject to your compliance with these TOS,
                    CrowdQ grants you a limited, non-exclusive, non-transferable, revocable license to access and use
                    the Service for its intended purpose.</li>
            </ul>
            <h4 class="font-weight-600 mt-3">7. Fees and Payments</h4>
            <ul class="text-neutral-100 mt-2">
                <li><span class="font-weight-bold">Premium Features:</span> Certain features of the Service may be
                    available only through paid subscriptions or one-time purchases ("Premium Features").</li>
                <li><span class="font-weight-bold">Payment Information:</span> If you choose to use Premium Features,
                    you agree to provide accurate and complete payment information and to authorize CrowdQ to charge
                    your designated payment method for the applicable fees.</li>
                <li><span class="font-weight-bold">Subscription Renewals:</span> If you purchase a subscription, it will
                    automatically renew at the end of each subscription period unless you cancel it before the renewal
                    date.</li>
                <li><span class="font-weight-bold">Fee Changes:</span> CrowdQ reserves the right to change the fees for
                    Premium Features at any time. We will provide you with reasonable notice of any fee changes before
                    they become effective.</li>
                <li><span class="font-weight-bold">Taxes:</span> You are responsible for paying any applicable taxes
                    (e.g., sales tax, VAT) related to your use of the Service.</li>
            </ul>
            <h4 class="font-weight-600 mt-3">8. Giveaways</h4>
            <ul class="text-neutral-100 mt-2">
                <li><span class="font-weight-bold">Facilitation Only:</span> CrowdQ provides tools and resources to help
                    users create and manage legally compliant giveaways, contests, and sweepstakes. However, CrowdQ does
                    not sponsor, endorse, or administer these giveaways, and we are not responsible for their conduct or
                    outcome.</li>
                <li><span class="font-weight-bold">Compliance Responsibility:</span> You are solely responsible for
                    ensuring that any giveaway you create or participate in complies with all applicable federal, state,
                    and local laws and regulations. This includes, but is not limited to, laws governing sweepstakes,
                    contests, promotions, and advertising.</li>
                <li><span class="font-weight-bold">Automated Rules:</span> CrowdQ's system automatically generates
                    giveaway rules based on your inputs and the applicable laws. While we strive to provide accurate and
                    up-to-date legal information, these generated rules are for informational purposes only and should
                    not be considered legal advice. You should consult with your own legal counsel to ensure full
                    compliance.</li>
                <li><span class="font-weight-bold">No Lottery Guarantee:</span> CrowdQ's system is designed to help you
                    avoid creating an illegal lottery by ensuring that no consideration (e.g., purchase, payment) is
                    required to enter a giveaway. However, you are ultimately responsible for ensuring that your
                    giveaway does not meet the legal definition of an illegal lottery in your jurisdiction.</li>
                <li><span class="font-weight-bold">Participant Information:</span> You agree to collect and use
                    participant information only in accordance with applicable privacy laws and CrowdQ's Privacy Policy.
                </li>
                <li><span class="font-weight-bold">Disputes:</span> CrowdQ is not responsible for resolving any disputes
                    that may arise between you and participants in your giveaways.</li>
                <li><span class="font-weight-bold">Indemnification:</span> You agree to indemnify and hold harmless
                    CrowdQ from any claims, damages, or liabilities arising from your giveaways, including but not
                    limited to claims related to non-compliance with applicable laws, rules, or regulations.</li>
            </ul>
            <h4 class="font-weight-600 mt-3">9. Disclaimer of Warranties</h4>
            <p class="text-neutral-100 mt-2">
                THE SERVICE IS PROVIDED "AS IS" AND "AS AVAILABLE" WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR
                IMPLIED. TO THE FULLEST EXTENT PERMITTED BY LAW, CROWDQ DISCLAIMS ALL WARRANTIES, INCLUDING BUT NOT
                LIMITED TO:
            </p>
            <ul class="text-neutral-100 mt-2">
                <li>IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT.</li>
                <li>WARRANTIES THAT THE SERVICE WILL BE UNINTERRUPTED, ERROR-FREE, OR SECURE.</li>
                <li>WARRANTIES THAT ANY DEFECTS OR ERRORS IN THE SERVICE WILL BE CORRECTED.</li>
                <li>WARRANTIES THAT THE SERVICE OR THE SERVERS THAT MAKE IT AVAILABLE ARE FREE OF VIRUSES OR OTHER
                    HARMFUL COMPONENTS.</li>
            </ul>
            <h4 class="font-weight-600 mt-3">10. Limitation of Liability</h4>
            <p class="text-neutral-100 mt-2">
                TO THE FULLEST EXTENT PERMITTED BY LAW, CROWDQ AND ITS AFFILIATES, OFFICERS, DIRECTORS, EMPLOYEES,
                AGENTS, AND LICENSORS WILL NOT BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR
                PUNITIVE DAMAGES, INCLUDING BUT NOT LIMITED TO:
            </p>
            <ul class="text-neutral-100 mt-2">
                <li>LOSS OF PROFITS, REVENUE, DATA, OR USE.</li>
                <li>COST OF PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES.</li>
                <li>BUSINESS INTERRUPTION.</li>
                <li>ANY OTHER INTANGIBLE LOSSES.</li>
            </ul>
            <p class="text-neutral-100 mt-2">
                ARISING OUT OF OR IN CONNECTION WITH YOUR USE OF OR INABILITY TO USE THE SERVICE, WHETHER BASED ON
                WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE), OR ANY OTHER LEGAL THEORY, EVEN IF CROWDQ HAS BEEN
                ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
            </p>
            <p class="text-neutral-100 mt-2">
                CROWDQ'S TOTAL AGGREGATE LIABILITY TO YOU FOR ANY AND ALL CLAIMS ARISING OUT OF OR IN CONNECTION WITH
                THESE TOS OR THE SERVICE WILL NOT EXCEED THE AMOUNT YOU HAVE PAID TO CROWDQ, IF ANY, IN THE TWELVE (12)
                MONTHS PRIOR TO THE EVENT GIVING RISE TO THE LIABILITY.
            </p>
            <h4 class="font-weight-600 mt-3">11. Dispute Resolution</h4>
            <p class="text-neutral-100 mt-2">
                <strong>Governing Law:</strong> These TOS shall be governed by and construed in accordance with the laws
                of the State of Delaware, United States, without regard to its conflict of laws principles.
            </p>
            <p class="text-neutral-100 mt-2">
                <strong>Informal Resolution:</strong> In the event of any dispute arising out of or relating to these
                TOS or the Service, you agree to first attempt to resolve the dispute informally by contacting CrowdQ at
                [Insert Contact Email].
            </p>
            <p class="text-neutral-100 mt-2">
                <strong>Binding Arbitration:</strong> If the dispute cannot be resolved informally within thirty (30)
                days, either party may elect to have the dispute finally and exclusively resolved by binding
                arbitration. Any election to arbitrate by one party shall be final and binding on the other. YOU
                UNDERSTAND THAT ABSENT THIS PROVISION, YOU WOULD HAVE THE RIGHT TO SUE IN COURT AND HAVE A JURY TRIAL.
                The arbitration shall be commenced and conducted under the Commercial Arbitration Rules of the American
                Arbitration Association ("AAA") and, where appropriate, the AAA’s Supplementary Procedures for Consumer
                Related Disputes ("AAA Consumer Rules"), both of which are available at the AAA website www.adr.org. The
                determination of whether a dispute is subject to arbitration shall be governed by the Federal
                Arbitration Act and determined by a court rather than an arbitrator. Your arbitration fees and your
                share of arbitrator compensation shall be governed by the AAA Rules and, where appropriate, limited by
                the AAA Consumer Rules. The arbitration may be conducted in person, through the submission of documents,
                by phone, or online. The arbitrator will make a decision in writing, and the award rendered by the
                arbitrator may be entered in any court having jurisdiction thereof.
            </p>
            <p class="text-neutral-100 mt-2">
                <strong>Class Action Waiver:</strong> YOU AND CROWDQ AGREE THAT EACH MAY BRING CLAIMS AGAINST THE OTHER
                ONLY IN YOUR OR ITS INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS
                OR REPRESENTATIVE PROCEEDING. Further, unless both you and CrowdQ agree otherwise, the arbitrator may
                not consolidate more than one person's claims and may not otherwise preside over any form of a
                representative or class proceeding.
            </p>
            <p class="text-neutral-100 mt-2">
                <strong>Exceptions:</strong> Notwithstanding the foregoing, either party may bring an individual action
                in small claims court or seek injunctive or other equitable relief in a court of competent jurisdiction
                to prevent the actual or threatened infringement, misappropriation, or violation of a party's
                copyrights, trademarks, trade secrets, patents, or other intellectual property rights.
            </p>
            <h4 class="font-weight-600 mt-3">12. Termination</h4>
            <p class="text-neutral-100 mt-2">
                <strong>By CrowdQ:</strong> CrowdQ may suspend or terminate your access to the Service, in whole or in
                part, at any time, with or without notice, for any reason, including but not limited to:
            </p>
            <ul class="text-neutral-100 mt-2">
                <li>Violation of these TOS.</li>
                <li>A request by law enforcement or other government agencies.</li>
                <li>Discontinuance or material modification of the Service.</li>
                <li>Unexpected technical or security issues or problems.</li>
                <li>Extended periods of inactivity.</li>
            </ul>
            <p class="text-neutral-100 mt-2">
                <strong>By You:</strong> You may terminate your account at any time by contacting CrowdQ at [Insert
                Contact Email] or through your account settings (if available).
            </p>
            <p class="text-neutral-100 mt-2">
                <strong>Effect of Termination:</strong> Upon termination, your right to access and use the Service will
                immediately cease. All provisions of these TOS which by their nature should survive termination shall
                survive termination, including, without limitation, ownership provisions, warranty disclaimers,
                indemnity, and limitations of liability.
            </p>
            <h4 class="font-weight-600 mt-3">13. Modifications to the TOS</h4>
            <p class="text-neutral-100 mt-2">
                CrowdQ reserves the right to modify these TOS at any time. We will post the updated TOS on our website
                and update the "Effective Date" at the top of this document. We may also provide you with notice of
                material changes by email or through the Service. Your continued use of the Service after any such
                changes constitutes your acceptance of the new TOS. If you do not agree to the modified TOS, you must
                discontinue your use of the Service.
            </p>

            <h4 class="font-weight-600 mt-3">14. Evolving Nature of the Service</h4>
            <p class="text-neutral-100 mt-2">
                CrowdQ is committed to continuous innovation and improvement. The Service may evolve over time as we add
                new features, refine existing ones, or respond to changes in technology or the legal landscape. We will
                update these TOS as needed to reflect any such changes that impact your rights or obligations. While we
                will make reasonable efforts to notify you of significant updates, we encourage you to review these TOS
                periodically.
            </p>
            <h4 class="font-weight-600 mt-3">15. General Provisions</h4>
            <ul class="text-neutral-100 mt-2">
                <li><strong>Entire Agreement:</strong> These TOS, together with our Privacy Policy, constitute the
                    entire agreement between you and CrowdQ regarding your use of the Service and supersede all prior or
                    contemporaneous communications and proposals, whether electronic, oral, or written, between you and
                    CrowdQ with respect to the Service.</li>
                <li><strong>Waiver:</strong> No waiver of any provision of these TOS shall be effective unless in
                    writing and signed by both you and CrowdQ.</li>
                <li><strong>Severability:</strong> If any provision of these TOS is held to be invalid or unenforceable,
                    such provision shall be struck and the remaining provisions shall be enforced.</li>
                <li><strong>Assignment:</strong> You may not assign these TOS or any of your rights or obligations
                    hereunder without the prior written consent of CrowdQ. CrowdQ may freely assign these TOS and its
                    rights and obligations hereunder without restriction.</li>
                <li><strong>No Third-Party Beneficiaries:</strong> These TOS are not intended to benefit any third
                    party, and no third party shall have any right to enforce any provision of these TOS.</li>
                <li><strong>Force Majeure:</strong> CrowdQ shall not be liable for any failure to perform its
                    obligations under these TOS due to causes beyond its reasonable control, including but not limited
                    to acts of God, war, terrorism, riots, embargoes, acts of civil or military authorities, fire,
                    floods, accidents, strikes, or shortages of transportation facilities, fuel, energy, labor, or
                    materials.</li>
            </ul>

            <h4 class="font-weight-600 mt-3">16. Contact Information</h4>
            <p class="text-neutral-100 mt-2">
                If you have any questions about these TOS or the Service, please contact us at:
            </p>
            <p class="text-neutral-100 mt-2">
                <a href="mailto:admin@crowdq.live" class="text-primary">admin@crowdq.live</a>
            </p>

            <p class="font-weight-600 mt-3 pb-5">Thank you for using CrowdQ!</p>
        </div>
    </div>
</template>

<script>

</script>
<style scoped>
.page-header {
    background-image: url('../../../assets/util/images/banner.jpg');
    background-size: cover;
    background-position: center;
    height: 100%;

}
</style>
