<template>
    <div class="modal fade" id="sendSongRequestModal" tabindex="-1" aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div class="modal-dialog modal-lg">
            <div class="modal-content bg-neutral-800">
                <div class="modal-header justify-content-between">
                    <h1 class="modal-title fs-5 text-neutral-25 font-weight-600" id="exampleModalLabel">SONG REQUEST
                    </h1>
                    <button type="button " class="bg-neutral-800 border-0" data-bs-dismiss="modal" aria-label="Close"><i
                            class="fa-solid fa-times text-neutral-25"></i></button>
                </div>
                <div class="modal-body d-flex flex-column pb-4">
                    <p class="text-neutral-25 text-3">Search</p>
                    <div class="row align-items-end">
                        <div class="col-9">
                            <div class="d-flex align-items-center gap-3 mt-2">
                                <div class="d-flex search-input">
                                    <button class="search-icon ps-2"><i class="fas fa-search"></i></button>
                                    <input class="song-request-input" type="text" v-model="searchTerm"
                                        @input="fetchResults" placeholder="Song Name...">
                                </div>

                            </div>
                        </div>
                        <!-- <div class="col-5">
                            <div class="d-flex align-items-center gap-3 mt-2">
                        <div class="d-flex search-input">
                            <button class="search-icon ps-2"><i class="fa-solid fa-user"></i></button>
                            <input class="song-request-input" type="text" v-model="artistTerm" @input="fetchResults"
                                placeholder="Artist Name...">
                        </div>
                       
                    </div>
                    </div> -->

                        <div class="col-3">
                            <button class="btn btn-primary ps-4 pe-4 search-button">Search</button>
                        </div>
                    </div>


                    <template v-if="searchingLoading">
                        <LoadingComponent />
                    </template>
                    <template v-else>
                        <div v-if="showDropdown && songSearchResults.length > 0" class="dropdown mt-4">
                            <div class="song-list row align-items-stretch">
                                <div v-for="(result, index) in songSearchResults" :key="index"
                                :class="['mt-2', 'p-2', 'col-6', 'col-sm-6', 'col-md-6', 'col-lg-4', { 'd-none': !result?.title }]">
                                    <template v-if="result?.title">
                                        <div
                                            class="d-flex flex-column gap-3 flex-1 p-3 bg-neutral-900 rounded song-card">
                                            <img class="rounded"
                                                :src="result?.artwork_url ? result?.artwork_url : result?.avatar_url" />
                                            <div class="d-flex flex-column flex-grow-1">
                                                <h5 class="text-white text-4 mb-1 text-break"> {{ result?.title }} </h5>
                                                <p class="song-album"> {{ result?.user?.username }} </p>
                                                <p class="song-album"> {{ result?.publisher_metadata?.album_title }} </p>
                                                <!-- Uncomment the following line if needed -->
                                                <!-- <a :href="result.external_urls.spotify" target="_blank"> <img src="../../../assets/spotify.png" alt="" class="spotifyicon mt-2 "> </a> -->
                                            </div>
                                            <button
                                                @click="isRquested(result) ? cancelRequest(result) : requestToPlay(result)"
                                                :class="[isRquested(result) ? 'btn-danger' : 'btn-primary', 'btn request_btn']">
                                                <i v-if="!isRquested(result)" class="fa-solid fa-paper-plane"></i>
                                                <i v-if="isRquested(result)" class="fa-solid fa-xmark"></i>
                                                <span class="ps-2 d-none d-lg-inline-block">{{
                                                    isRquested(result) ? 'Cancel' : 'Request'
                                                    }}</span>
                                            </button>
                                        </div>
                                    </template>
                                </div>

                            </div>

                        </div>
                        <div v-else-if="showDropdown && songSearchResults.length == 0">
                            <NoData />
                        </div>
                        <div v-else class="row flex-grow-1">
                            <div class="col-6 d-flex flex-column align-items-center justify-content-center">
                                <h1 class="search_text">Search for your favourite <span>song</span>.</h1>
                            </div>
                            <div class="col-6">
                                <div class="h-100 d-flex flex-column align-items-center justify-content-center">
                                    <img class="w-50" :src="require('@/assets/util/images/song_request_loading.svg')"
                                        alt="">
                                </div>
                            </div>
                        </div>
                    </template>
                </div>
                <!-- <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <button type="button" class="btn btn-primary">Save changes</button>
                </div> -->
            </div>
        </div>
    </div>



</template>

<script>
import store from '@/store/index.js';
import { ref, toRefs } from 'vue';
import { useRoute } from 'vue-router';
import NoData from '@/components/Shared/NoData.vue';
import LoadingComponent from '@/components/Shared/LoadingComponent.vue';

export default ({
    components: {
        NoData,
        LoadingComponent
    },
    props: {
        requestSongs: Array
    },
    setup(props) {
        const route = useRoute();
        const eventId = route.params.eventId;
        const showDropdown = ref(false);
        const searchTerm = ref('');
        const artistTerm = ref('');
        const songSearchResults = ref([]);
        let searchTimer = null;
        const { requestSongs } = toRefs(props);
        const requestedIds = ref(requestSongs.value.map(song => song.song_id));
        const searchingLoading = ref(false);

        const fetchResults = async () => {
            searchingLoading.value = true;
            if (searchTimer) {
                clearTimeout(searchTimer);
            }
            searchTimer = setTimeout(async () => {
                if (searchTerm.value.length || artistTerm.value.length) {
                    console.log(searchTerm.value)
                    await store.dispatch('attendeeEvent/fetchSongRequest', { artistTerm: artistTerm.value, searchTerm: searchTerm.value });
                    songSearchResults.value = store.getters['attendeeEvent/songSearchRequestData'];
                    console.log(store.getters['attendeeEvent/songSearchRequestData'])

                    showDropdown.value = true;
                } else {
                    showDropdown.value = false;
                }
                searchingLoading.value = false;
            }, 1000);
        }

        const requestToPlay = async (song) => {
            const songRequestData = {
                eventId: eventId,
                attendeeId: store.getters['user/getUserId'],
                songId: song?.id,
                songName: song?.title,
                songImage: song?.avatar_url?  song?.avatar_url :  song?.artwork_url ,
                songLink: null,
                songDuration: song?.duration ? song?.duration : "5",
                album_name: song?.publisher_metadata?.album_title ? song?.publisher_metadata?.album_title : "N/A",
                songArtist: song?.user?.username
            }
            console.log(songRequestData)
            await store.dispatch('attendeeEvent/sendSongRequest', songRequestData);
            requestedIds.value.push(song.id);
            searchTerm.value = '';
            artistTerm.value = '';
        }
        const isRquested = song => {
            for (const element of requestedIds.value) {
                if (song.id == element) {
                    return true;
                }
            }
            return false;
        }

        const cancelRequest = async (song) => {
            const body = {
                song_id: song.id,
                event_id: eventId,
            };
            await store.dispatch('attendeeEvent/cancelSongRequest', body);
            requestedIds.value.pop(song.id);
        }

        return {
            searchTerm,
            artistTerm,
            fetchResults,
            showDropdown,
            songSearchResults,
            requestToPlay,
            requestedIds,
            isRquested,
            cancelRequest,
            searchingLoading,
        }
    }
})
</script>
<style scoped>
.spotifyicon {
    width: 20px;
}

.song-list {
    padding-left: 0px;
}

.song-list .song-card {
    display: flex;
    flex-direction: column;
    height: 100%;
    /* Ensure full height for each card */
}

.song-card img {
    max-width: 100%;
    height: auto;
}

.song-list .song-card button {
    margin-top: auto;
    /* Push button to the bottom */
}

ul li {
    width: 100%;
}

.song-album {
    font-size: 15px;
    margin-top: 6px;
}

.song-img {
    width: 80px;
    height: 80px;
}

.search-input {
    width: 100%
}

.song-request-input {
    border: 2px solid #A4A4A5;
    border-left: none;
    width: 100%;
    height: 44px;
    background-color: #333334;
    color: #E4E4E5;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}

.song-request-input:focus {
    outline: none;
    border: 2px solid #A4A4A5;
    border-left: none;
    background-color: #333334 !important;
}

.search-icon {
    border: 2px solid #A4A4A5;
    border-right: none;
    height: 44px;
    background-color: #333334;
    color: #E4E4E5;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;

}

.search-button {
    height: 44px;
}

#sendSongRequestModal .modal-content {
    min-height: 90vh;
}

.search_text {
    text-align: center;
    font-size: 32px;
}

.search_text span {
    font-size: 52px;
    color: #6A04BA;
}

.song_search_details {
    flex: 1;
    overflow-wrap: anywhere;
}
</style>
